<template>
<hr />
<div class='bottom'>
<h4 class='lbottom'>Copyright&copy; 2024 Rhodium Systems Inc. All Rights Reserved</h4>
<h4 class='rbottom'><img src='/logo-48high.svg' alt="Rhodium Systems Inc."/></h4>
<Toast position="top-center" />
</div>
</template>

<script>
import Toast from 'primevue/toast'
export default {
	name: 'Bottom',
	components: {
		Toast
	},

	setup() {
	
	}
}
</script>

<style lang="scss">
.bottom {
  background: #efefef;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;

}

.lbottom {
margin: 0;
margin-left: 10px;
padding: 10px;
}
.rbottom {
margin: 0;
margin-right: 30px;
padding: 10px;
}

</style>

