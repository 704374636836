<template>
  <nav class='navbar'>
	<div class='lnav'>
	<img src='/rit-logo-white-48high.svg' height="40" alt="ResorsIT"/>
	<h2 class='lhead'>
	&mdash;&nbsp;&nbsp;
	{{ title }}
	</h2>
	</div>
	<div class='rhead'>
	</div>
  </nav>
</template>

<script>
import { ref, watchEffect } from 'vue'
export default {
	name: 'NavBar',
	props: {
		title: {
			type: String,
			default: 'Access Request'
		}
	},

	setup() {
	
	const setup = ref({});
	const error = ref('');


	//console.log(setup.value)

	return { setup, error }
	}
}
</script>

<style lang="scss">

.lhead {
  margin-left: 20px;
  vertical-align: middle;
  align-items: center;
}
.rhead {
  margin-right: 20px;
}
.lnav {

  display: flex;
  align-items: center;
}
nav {
  padding: 5px;
  background: #9e1b32;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;

  a {
    font-weight: bold;
    color: #ffffff;

    &.router-link-exact-active {
      color: lime;
    }
  }
}
</style>

