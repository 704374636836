import { ref } from 'vue'


const putRequest = () => {

const rstatus = ref({
	'state': '',
	'error': '',
	'message': ''
	})

	// state is one of '' (nothing submitted)
	//         'rejected' (server rejected the request for some reason)
	//         'accecpted' (message from server will be presented to user)

const putrequest = async ( ureq ) => {
	const apiurl = process.env.VUE_APP_APIURL
	//rstatus.value.state = 'rejected'
	//rstatus.value.message = 'The function is not yet written'
	console.log("URL " + apiurl + '/request/v1')
	console.log("PutRequest " + JSON.stringify(ureq.value, null, 4))

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(ureq.value)
	};


	try {
		let data = await fetch(apiurl + '/request/v1', requestOptions)
		if(!data.ok) {
			throw Error('no available data')
		}
		rstatus.value = await data.json()
		console.log("From Server " + JSON.stringify(rstatus.value, null, 4))
	}
	catch(err) {
		console.log(err)
		error.value = err.message
	}

}


return { rstatus, putrequest }
}

export default putRequest


