import { ref } from 'vue'


const getSetup = () => {

const setup = ref({})
const error = ref(null)

const loadsetup = async ( ) => {
	const apiurl = process.env.VUE_APP_APIURL
	console.log("setup api :" + apiurl + '/setup/v1')

	try {
		let data = await fetch(apiurl + '/setup/v1')
		if(!data.ok) {
			throw Error('no available data')
		}
		setup.value = await data.json()
	}
	catch(err) {
		console.log(err)
		error.value = err.message
	}

	// Now to add some stuff
	setup.value.langs = ['en-US','en']

	setup.value.inst = {}
	setup.value.inst.firstp = 'Fill out the form below to request access to this site.'
	setup.value.inst.twop = `Your user ID must be unique for this site. The Email that you enter below will
		become your primary email for this account and will be used for password recovery.
		`
		//console.log(setup.value)
}


return { setup, error, loadsetup }
}

export default getSetup


