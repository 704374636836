import { ref } from 'vue'


const getVerify = () => {

const verify = ref({
	invalid: true,
	reason: 'Your request could not be found',
	message: ''
	})
const smsg = ref({
	run: false,
	sucess: true,
	message: 'The process is NYI'
	})
const verror = ref(null)

const loadverify = async ( key ) => {
	const apiurl = process.env.VUE_APP_APIURL
	console.log("setup api :" + apiurl + '/verify/v1' + key)

	try {
		let data = await fetch(apiurl + '/verify/v1/' + key)
		if(!data.ok) {
			throw Error('no available data')
		}
		verify.value = await data.json()
	}
	catch(err) {
		console.log(err)
		verror.value = err.message
	}

}
const psubmit = async ( preq ) => {
	const apiurl = process.env.VUE_APP_APIURL
	console.log("setup api :" + apiurl + '/ipass/v1')
	console.log("psubmit " + JSON.stringify(preq.value, null, 4))
	//smsg.value.run = true

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(preq.value)
	};


	try {
		let data = await fetch(apiurl + '/ipass/v1', requestOptions)
		if(!data.ok) {
			throw Error('no available data')
		}
		smsg.value = await data.json()
		console.log("From Server " + JSON.stringify(smsg.value, null, 4))
	}
	catch(err) {
		console.log(err)
		error.value = err.message
	}


}


return { verify, verror, loadverify, psubmit, smsg } 
}

export default getVerify



