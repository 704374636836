<template>
  <div class="verify">
	<Navbar />
	<h3 class='bheading'>Verify your email and set your password</h3>
	<p class='inst'>This site is {{ setup.site.description }} <br /> It is run by {{ setup.site.owner }}</p>
	<div class="ivdiv" v-if="verify.invalid">
	<h1 class='ivhead'>{{ verify.reason }}</h1>
	<p class='ivmsg'>{{ verify.message }}</p>
	</div>
	<div class='valdiv' v-else>
		<p class='valmsg'>{{ verify.message }}</p>
		<div class='form' v-if="! smsg.success">
			<h3 class='flabel'>Your Password</h3>
			<Password v-model="password" :feedback="false" toggleMask :invalid="! valid"/>
			<p class='fhint'>
			Your password must be at least {{ verify.length }} characters long. Any ASCII characters execpt for the backspace are valid.
			</p>
			<p class='feedback'>Password Length = {{ plength }}</p>
			<h3 class='flabel'>Verify Password</h3>
			<Password v-model="vpassword" :feedback="false" toggleMask :invalid= "! match"/>
			<div v-if="valid && match">
				<p />
				<p />
				<Button class="savebutton" label='Submit' icon='pi pi-cloud-upload' @click='dosubmit' />
			</div>
		</div>
		<div class='sdiv' v-if="smsg.run">
		<h3 class='smsg'>{{ smsg.message }}</h3>
		<h2 class='redirect' v-if="smsg.success">
		<a :href="smsg.redirect">Press here to log into ResorsIT</a>
		</h2>
		</div>
	</div>

    </div>
</template>


<script>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import getSetup from '@/utility/getSetup'
import getVerify from '@/utility/getVerify'
import Password from 'primevue/password'
import Button from 'primevue/button'



export default {
	components: { 
		Password,
		Button,
		Navbar
	},
	setup() {

	const route = useRoute()
	const mode = ref('UNKNOWN')
	const password = ref('')
	const plength = ref(0)
	const vpassword = ref('')
	const valid = ref(false)
	const match = ref(false)
	const preq = ref({
		'key': '',
		'pass': '',
		'vpass': '',
		});

	const key = route.params.key

	console.log(`Got key of [${key}] ` )

	const { setup, error, loadsetup } = getSetup()
	const { verify, verror, loadverify, psubmit, smsg } = getVerify()

	mode.value = process.env.NODE_ENV
	loadsetup()
	loadverify(key)

	watch(password, () => {
		plength.value = password.value.length
		if(password.value.length < verify.value.length) {
			valid.value = false
		} else {
			valid.value = true
		}
		console.log(`watch run valid = ${valid.value}`)
	})
	watch(vpassword, () => {
		if(password.value === vpassword.value) {
			match.value = true
		} else {
			match.value = false
		}
		console.log(`watch run match = ${match.value}`)
	})
	const dosubmit = () => {
		preq.value.key = key
		preq.value.pass = password
		preq.value.vpass = vpassword
		console.log("DOSUBMIT " + JSON.stringify(preq.value, null, 4))
		psubmit(preq)
		
	}

	return { setup, error, mode, verify, password, vpassword, plength, valid, match, dosubmit, smsg }
	}
}

</script>
<style lang="scss" scoped>
.bheading {
	color: #9e1b32;
	font-weight: bold;
	font-size: xx-large;

}
.inst {
	color: #9e1b32;
	font-size: large;
	max-width: 50em;
}
.form {
	padding: 15px;
	background: white;
	border-radius: 10px;
	max-width: 90em;
}
.flabel {
	margin-bottom: 0px;
}
.fhint {
	margin-top: 0px;
}
.ivhead {
	color: red;
	font-weight: bold;
	font-size: xx-large;
}
</style>

