<template>
	<div class="container">
		<router-view class='vuebody'/>
		<Bottom />
	</div>
</template>

<script>
import Bottom from './components/Bottom.vue'
export default {
	components: { 
		Bottom 
	},
	setup() {
	}
}

</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.p-component {
background: #efefef;
margin: 50px;
}
.vuebody {
  background: #efefef;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
}

.p-multiselect-item {
	margin: 10px;
}
</style>
