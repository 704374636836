import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Verify from '../views/Verify.vue'

const routes = [
  {
	path: '/verify/:key',
	name: 'verify',
	component: Verify
  },
  {
    path: '/',
    name: 'home',
    component: Home
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
