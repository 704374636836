import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
//import 'primevue/resources/themes/saga-blue/theme.css'  // theme
import 'primevue/resources/themes/aura-light-blue/theme.css'  // theme
import 'primeicons/primeicons.css'                      // icons
import '/node_modules/primeflex/primeflex.css'          // PrimeFlex

// import components
import Button from 'primevue/button'
import TreeTable from 'primevue/treetable'
import Column from 'primevue/column'


const app = createApp(App).use(router)

app.use(PrimeVue)
app.use(ToastService);

app.component('Button', Button)

app.mount('#app')
